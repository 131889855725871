import { DashboardType, NewDashboard } from "./type";

// Auth intial state
const initialAuthState = {
  dashboard: [],
  isLoading: true,
  error: null,
  newDashnoardOneLoading: true,
  newDashnoardOne: null,
  newDashnoardOneError: null,
  newDashnoardTwoLoading: true,
  newDashnoardTwo: null,
  newDashnoardTwoError: null,
  newDashnoardThree: null,
  newDashnoardThreeLoading: true
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case DashboardType.GET_DASHBOARD_REQUEST:
      return { ...state, isLoading: true };
    case DashboardType.GET_DASHBOARD_SUCCESS:
      return { ...state, isLoading: false, dashboard: action.payload.data };
    case DashboardType.GET_DASHBOARD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case NewDashboard.GET_NEW_DASHBOARD_ONE_REQUEST:
      return { ...state, newDashnoardOneLoading: true };
    case NewDashboard.GET_NEW_DASHBOARD_ONE_SUCCESS:
      return { ...state, newDashnoardOneLoading: false, newDashnoardOne: action.payload.data };
    case NewDashboard.GET_NEW_DASHBOARD_ONE_FAIL:
      return { ...state, newDashnoardOneLoading: false, newDashnoardOneError: action.error };
    case NewDashboard.GET_NEW_DASHBOARD_TWO_REQUEST:
      return { ...state, newDashnoardTwoLoading: true };
    case NewDashboard.GET_NEW_DASHBOARD_TWO_SUCCESS:
      return { ...state, newDashnoardTwoLoading: false, newDashnoardTwo: action.payload.data };
    case NewDashboard.GET_NEW_DASHBOARD_TWO_FAIL:
      return { ...state, newDashnoardTwoLoading: false, newDashnoardTwoError: action.error };
    case NewDashboard.GET_NEW_DASHBOARD_THREE_REQUEST:
      return { ...state, newDashnoardThreeLoading: true };
    case NewDashboard.GET_NEW_DASHBOARD_THREE_SUCCESS:
      return { ...state, newDashnoardThreeLoading: false, newDashnoardThree: action.payload.data };
    case NewDashboard.GET_NEW_DASHBOARD_THREE_FAIL:
      return { ...state, newDashnoardThreeLoading: false, newDashnoardThreeError: action.error };
    default:
      return state;
  }
};
