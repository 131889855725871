import { EventListType } from "./type";

// Auth intial state
const initialAuthState = {
  cancelledEvents: [],
  isLoading: false,
  error: null,
  isAddLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case EventListType.GET_EVENT_CANCEL_REQUEST:
      return { ...state, isLoading: true };
    case EventListType.GET_EVENT_CANCEL_SUCCESS:
      return { ...state, isLoading: false, cancelledEvents: action.payload.data };
    case EventListType.GET_EVENT_CANCEL_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventListType.ADD_EVENT_CANCEL_REQUEST:
      return { ...state, isAddLoading: true };
    case EventListType.ADD_EVENT_CANCEL_SUCCESS:
      return { ...state, isAddLoading: false };
    case EventListType.ADD_EVENT_CANCEL_FAIL:
      return { ...state, isAddLoading: false, error: action.error };
    case EventListType.ADD_CHARGES_REQUEST:
      return { ...state, isAddLoading: true };
    case EventListType.ADD_CHARGES_SUCCESS:
      return { ...state, isAddLoading: false };
    case EventListType.ADD_CHARGES_FAIL:
      return { ...state, isAddLoading: false, error: action.error };

    default:
      return state;
  }
};
