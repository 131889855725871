import { ReportType } from "./type";

// Organizers intial state
const initialAuthState = {
  checker_reports: null,
  chekersDate: [],
  isLoading: true,
  error: null,
  isCheckerReportLoading: true
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case ReportType.GET_CHECKER_REPORTS_REQUEST:
      return { ...state, isCheckerReportLoading: true };
    case ReportType.GET_CHECKER_REPORTS_SUCCESS:
      return { ...state, isCheckerReportLoading: false, checker_reports: action.payload.data };
    case ReportType.GET_CHECKER_REPORTS_FAIL:
      return { ...state, isCheckerReportLoading: false, error: action.error };

    case ReportType.GET_CHECKER_DATE_LIST_REQUEST:
      return { ...state, isLoading: true };
    case ReportType.GET_CHECKER_DATE_LIST_SUCCESS:
      return { ...state, isLoading: false, chekersDate: action.payload.data };
    case ReportType.GET_CHECKER_DATE_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};
