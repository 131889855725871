import { ReportType } from "./type";

// Organizers intial state
const initialAuthState = {
  ageReports: null,
  isAgeReportLoading: true,
  error: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case ReportType.GET_AGE_REPORTS_REQUEST:
      return { ...state, isAgeReportLoading: true };
    case ReportType.GET_AGE_REPORTS_SUCCESS:
      return { ...state, isAgeReportLoading: false, ageReports: action.payload.data };
    case ReportType.GET_AGE_REPORTS_FAIL:
      return { ...state, isAgeReportLoading: false, error: action.error };

    default:
      return state;
  }
};
