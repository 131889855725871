export const EventListType = {
  GET_EVENT_CANCEL_REQUEST: "GET_EVENT_CANCEL_REQUEST",
  GET_EVENT_CANCEL_SUCCESS: "GET_EVENT_CANCEL_SUCCESS",
  GET_EVENT_CANCEL_FAIL: "GET_EVENT_CANCEL_FAIL",
  ADD_EVENT_CANCEL_REQUEST: "ADD_EVENT_CANCEL_REQUEST",
  ADD_EVENT_CANCEL_SUCCESS: "ADD_EVENT_CANCEL_SUCCESS",
  ADD_EVENT_CANCEL_FAIL: "ADD_EVENT_CANCEL_FAIL",
  ADD_CHARGES_REQUEST: "ADD_CHARGES_REQUEST",
  ADD_CHARGES_SUCCESS: "ADD_CHARGES_SUCCESS",
  ADD_CHARGES_FAIL: "ADD_CHARGES_FAIL"
};
