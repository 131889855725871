import { PaymentType } from "./type";

//sponsers/promoter intial state
const initialAuthState = {
  paymentList: null,
  error: null,
  isLoading: true,
  isAddLoading:false,
  isEditLoading:false,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    // payment-list payment
    case PaymentType.GET_PAYMENT_REQUEST:
      return { ...state, isLoading: true };
    case PaymentType.GET_PAYMENT_SUCCESS:
      return { ...state, isLoading: false, paymentList: action.payload.data };
    case PaymentType.GET_PAYMENT_FAIL:
      return { ...state, isLoading: false, error: action.error };

    // payment-add payment
    case PaymentType.ADD_PAYMENT_REQUEST:
      return { ...state, isAddLoading: true };
    case PaymentType.ADD_PAYMENT_SUCCESS:
      return { ...state, isAddLoading: false };
    case PaymentType.ADD_PAYMENT_FAIL:
      return { ...state, isAddLoading: false, error: action.error };

    // payment-edit payment
    case PaymentType.EDIT_PAYMENT_REQUEST:
      return { ...state, isEditLoading: true };
    case PaymentType.EDIT_PAYMENT_SUCCESS:
      return { ...state, isEditLoading: false };
    case PaymentType.EDIT_PAYMENT_FAIL:
      return { ...state, isEditLoading: false, error: action.error };

  

    default:
      return state;
  }
};
