import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { CloseIcon, ExclamationRoundFillIcon } from "../../components/Icons";

export default function LogoutDialog({ openLogout, onHandleLogout, handleLogoutClose }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div>
      <Dialog
        maxWidth={"lg"}
        open={openLogout}
        onClose={handleLogoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton sx={{ position: "absolute", top: "5px", right: "5px" }} onClick={handleLogoutClose}>
          <CloseIcon width="25px" height="25px" color="rgba(0, 0, 0, 0.54)" />
        </IconButton>
        <DialogTitle className="modal-header">
          <Box
            display="flex"
            justifyContent="start"
            sx={{
              alignItems: "center",
              display: "flex",
              gap: "8px",
              textTransform: "capitalize"
            }}
          >
            <ExclamationRoundFillIcon color={colors.danger[900]} />
            LOGOUT ?
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Are you sure you want to logout?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleLogoutClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={[
              { bgcolor: colors.primary[900] },
              {
                "&:hover": {
                  bgcolor: colors.primary[800]
                }
              }
            ]}
            onClick={onHandleLogout}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
