import { useTheme } from "@emotion/react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ErrorMSG from "../../components/ErrorMSG";
import MyDialog from "../../components/MyDialog";
import { tokens } from "../../theme";
import { passwordRegex } from "../../utils/regex";
import { addConfirmPassword } from "../login/redux/action";
import { HidePasswordIcon, ShowPasswordIcon } from "../../components/Icons";

export default function ChangePassword({ openDIalog, handleClose }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // main state to store the payload
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: ""
    }
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      await dispatch(addConfirmPassword({ password: data.password })).then(async (data) => {
        if (data && data?.payload?.status) {
          toast.success(data.payload.message);
          reset({ previousPassword: "", password: "", confirmPassword: "" });
          handleClose();
        } else {
          toast.error(data.error);
          handleClose();
        }
      });
    }
  };

  useEffect(() => {
    if (!openDIalog) {
      reset({ previousPassword: "", password: "", confirmPassword: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDIalog]);

  return (
    <>
      <MyDialog
        maxWidth="sm"
        open={openDIalog}
        handleClose={() => {
          handleClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <DialogTitle className="modal-header">CHANGE PASSWORD</DialogTitle>
          <DialogContent>
            <Box m="10px">
              <Grid container spacing={gridSpacing}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      size="small"
                      label={
                        <p className="my-0">
                          New Password <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="disabledPassEyeIcon"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              disabled={watch().password.length === 0}
                            >
                              {showPassword ? (
                                <HidePasswordIcon width="20px" height="20px" color={colors.primary[900]} />
                              ) : (
                                <ShowPasswordIcon width="20px" height="20px" color={colors.primary[900]} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("password", { required: true, pattern: passwordRegex })}
                    />
                    {errors.password && errors.password.type === "required" && (
                      <ErrorMSG text="New Password is required" />
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <ErrorMSG text="Password at least 1 uppercase, 1 lowercase, 1 special character, 1 number, Min 8, Max 30" />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type={showConfirmPassword ? "text" : "password"}
                      size="small"
                      label={
                        <p className="my-0">
                          Confirm New Password <span className="danger mr-0">*</span>
                        </p>
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="disabledPassEyeIcon"
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                              disabled={watch().confirmPassword.length === 0}
                            >
                              {showConfirmPassword ? (
                                <HidePasswordIcon width="20px" height="20px" color={colors.primary[900]} />
                              ) : (
                                <ShowPasswordIcon width="20px" height="20px" color={colors.primary[900]} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("confirmPassword", {
                        required: true,
                        validate: (val) => control._formValues.password === val
                      })}
                    />
                    {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                      <ErrorMSG text="Confirm New Password is required" />
                    )}
                    {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                      <ErrorMSG text="Your Confirm password does not match" />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={[
                { bgcolor: colors.primary[900] },
                {
                  "&:hover": {
                    bgcolor: colors.primary[800]
                  }
                }
              ]}
            >
              {/* {isLoading ? "Saving..." : "Save"} */}
              Save
            </Button>
          </DialogActions>
        </form>
      </MyDialog>
    </>
  );
}
