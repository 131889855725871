export const transactionType = {
  // repots constants
  GET_REPORTS_TRANSACTION_REQUEST: "GET_REPORTS_TRANSACTION_REQUEST",
  GET_REPORTS_TRANSACTION_SUCCESS: "GET_REPORTS_TRANSACTION_SUCCESS",
  GET_REPORTS_TRANSACTION_FAIL: "GET_REPORTS_TRANSACTION_FAIL",
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAI: "GET_EVENT_FAIL",
  GET_TRANSACTION_CARD_REQUEST: "GET_TRANSACTION_CARD_REQUEST",
  GET_TRANSACTION_CARD_SUCCESS: "GET_TRANSACTION_CARD_SUCCESS",
  GET_TRANSACTION_CARD_FAIL: "GET_TRANSACTION_CARD_FAIL"
};
