import { SupportType } from "./type";

// Organizers intial state
const initialAuthState = {
  isLoading: true,
  error: null,
  supportList: null,
  support: null,
  totalDocs: 0,
  isSupportLoading: true,
  supportDashboard: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SupportType.GET_SUPPORT_LIST_REQUEST:
      return { ...state, isLoading: true };
    case SupportType.GET_SUPPORT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        supportList: action.payload.supportList,
        totalDocs: action.payload.totalDocs
      };
    case SupportType.GET_SUPPORT_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case SupportType.GET_SINGLE_SUPPORT_REQUEST:
      return { ...state, isLoading: true };
    case SupportType.GET_SINGLE_SUPPORT_SUCCESS:
      return { ...state, isLoading: false, support: action.payload.data };
    case SupportType.GET_SINGLE_SUPPORT_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case SupportType.GET_DASHBOARD_SUPPORT_REQUEST:
      return { ...state, isSupportLoading: true };
    case SupportType.GET_DASHBOARD_SUPPORT_SUCCESS:
      return { ...state, isSupportLoading: false, supportDashboard: action.payload.data };
    case SupportType.GET_DASHBOARD_SUPPORT_FAIL:
      return { ...state, isSupportLoading: false, error: action.error };
    default:
      return state;
  }
};
