import React, { Suspense, lazy, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/global/Layout";
import { authCheck } from "./pages/login/redux/action";
import { AuthType } from "./pages/login/redux/type";
import { getCookie, getCountryCode, setCookie } from "./utils/custom";
import GlobalLoader from "./components/GlobalLoader";
import "./pages/login/style.css";
const Login = lazy(() => import("./pages/login/LoginScreen"));
const Register = lazy(() => import("./pages/login/RegisterSceen"));
const VerifyEmailScreen = lazy(() => import("./pages/login/VerifyEmailScreen"));
const AutoLoginScreen = lazy(() => import("./pages/login/AutoLoginScreen"));

export function PrivateRoute() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  useEffect(() => {
    if (user != null) {
      dispatch(authCheck()).then((data) => {
        if (data?.type === "AUTH_CHEK_SUCCESS") {
          if (data?.payload?.status === false) {
            dispatch({ type: AuthType.LOG_OUT });
          }
        }
        if (data?.type === "AUTH_CHEK_FAIL") {
          dispatch({ type: AuthType.LOG_OUT });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (getCookie("country-code")) {
      dispatch({
        type: AuthType.GET_COUNTRY,
        payload: { data: getCookie("country-code") }
      });
    } else {
      if (getCountryCode() !== undefined) {
        setCookie("country-code", getCountryCode());
        dispatch({
          type: AuthType.GET_COUNTRY,
          payload: { data: getCountryCode() }
        });
      } else {
        dispatch({
          type: AuthType.GET_COUNTRY,
          payload: { data: getCountryCode() }
        });
        setCookie("country-code", "US");
      }
    }
  }, [dispatch]);

  return user && user != null && user !== undefined ? (
    <Layout />
  ) : (
    <Suspense fallback={<GlobalLoader />}>
      <Routes>
        <Route exact path="/autologin/:orgId/:go" element={<AutoLoginScreen />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verify-email/:token" element={<VerifyEmailScreen />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Suspense>
  );
}
