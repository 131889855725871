import { ReportType } from "./type";

// Organizers intial state
const initialAuthState = {
  reports: null,
  salesReports: null,
  isReportsLoading: false,
  error: null,
  paymentDetails: null,
  isCardReportLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case ReportType.GET_REPORTS_REQUEST:
      return { ...state, isCardReportLoading: true };
    case ReportType.GET_REPORTS_SUCCESS:
      return { ...state, isCardReportLoading: false, reports: action.payload.data };
    case ReportType.GET_REPORTS_FAIL:
      return { ...state, isCardReportLoading: false, error: action.error };
    case ReportType.GET_SALES_REPORTS_REQUEST:
      return { ...state, isReportsLoading: true };
    case ReportType.GET_SALES_REPORTS_SUCCESS:
      return { ...state, isReportsLoading: false, salesReports: action.payload.data };
    case ReportType.GET_SALES_REPORTS_FAIL:
      return { ...state, isReportsLoading: false, error: action.error };
    case ReportType.GET_PAYMENT_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case ReportType.GET_PAYMENT_DETAILS_SUCCESS:
      return { ...state, isLoading: false, paymentDetails: action.payload.data };
    case ReportType.GET_PAYMENT_DETAILS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case ReportType.ADD_REFUND_REQUEST:
      return { ...state, isLoading: true };
    case ReportType.ADD_REFUND_SUCCESS:
      return { ...state, isLoading: false };
    case ReportType.ADD_REFUND_FAIL:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};
