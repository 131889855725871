import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getCookie } from "../../../utils/custom";
import { AuthType } from "./type";

// Auth intial state
const initialAuthState = {
  user: JSON.parse(localStorage.getItem("adminInfo")) ? JSON.parse(localStorage.getItem("adminInfo")) : null,
  isLoading: true,
  registerLoading: false,
  error: null,
  isPaymentLoading: false,
  isPaymentGetLoading: true,
  isPaymentEditLoading: false,
  paymentDetails: null,
  countryCode: getCookie("country-code") || "US",
  isNotificationLoading: false
};

export const reducer = persistReducer(
  // Set persist key
  {
    storage,
    key: "v713-demo1-auth",
    whitelist: ["user"]
  },

  (state = initialAuthState, action) => {
    switch (action.type) {
      case AuthType.USER_SIGNIN_REQUEST:
        return { ...state, registerLoading: true };
      case AuthType.USER_SIGNIN_SUCCESS:
        return { ...state, user: action.payload.data, registerLoading: false };
      case AuthType.USER_SIGNIN_FAIL:
        return { ...state, registerLoading: false, error: action.payload };
      case AuthType.SAVE_PAYMENT_REQUEST:
        return { ...state, isPaymentLoading: true };
      case AuthType.SAVE_PAYMENT_SUCCESS:
        return { ...state, isPaymentLoading: false };
      case AuthType.SAVE_PAYMENT_FAIL:
      case AuthType.UPDATE_PAYMENT_REQUEST:
        return { ...state, isPaymentEditLoading: true };
      case AuthType.UPDATE_PAYMENT_SUCCESS:
        return { ...state, isPaymentEditLoading: false };
      case AuthType.UPDATE_PAYMENT_FAIL:
        return { ...state, isPaymentEditLoading: false, error: action.payload };
      case AuthType.GET_PAYMENT_REQUEST:
        return { ...state, isPaymentGetLoading: true };
      case AuthType.GET_PAYMENT_SUCCESS:
        return { ...state, isPaymentGetLoading: false, paymentDetails: action.payload.data };
      case AuthType.GET_PAYMENT_FAIL:
        return { ...state, isPaymentGetLoading: false, error: action.payload };
      case AuthType.LOG_OUT:
        return { ...state, isLoading: false, user: null };
      case AuthType.AUTH_CHEK_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.AUTH_CHEK_SUCCESS:
        return { ...state, isLoading: false, user: action.payload.data };
      case AuthType.AUTH_CHEK_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.REFRESH_CAPTCHA_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.REFRESH_CAPTCHA_SUCCESS:
        return { ...state, isLoading: false };
      case AuthType.REFRESH_CAPTCHA_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.CHEK_CAPTCHA_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.CHEK_CAPTCHA_SUCCESS:
        return { ...state, isLoading: false };
      case AuthType.CHEK_CAPTCHA_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.PASSWORD_CHANGE_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.PASSWORD_CHANGE_SUCCESS:
        return { ...state, isLoading: false };
      case AuthType.PASSWORD_CHANGE_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.GET_PROFILE_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.GET_PROFILE_SUCCESS:
        return { ...state, isLoading: false, user: { ...state.user, ...action.payload.data } };
      case AuthType.GET_PROFILE_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.UPDATE_PROFILE_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.UPDATE_PROFILE_SUCCESS:
        return { ...state, isLoading: false };
      case AuthType.UPDATE_PROFILE_FAIL:
        return { ...state, isLoading: false, error: action.error };
      case AuthType.AUTO_LOGIN_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.AUTO_LOGIN_SUCCESS:
        return { ...state, user: action.payload.data, isLoading: false };
      case AuthType.AUTO_LOGIN_FAIL:
        return { ...state, isLoading: false, error: action.payload };
      case AuthType.NOTIFICATION_REQUEST:
        return { ...state, isNotificationLoading: true };
      case AuthType.NOTIFICATION_SUCCESS:
        return { ...state, isNotificationLoading: false, notifications: action.payload.data };
      case AuthType.NOTIFICATION_FAIL:
        return { ...state, isNotificationLoading: false };
      case AuthType.NOTIFICATION_READ_REQUEST:
        return { ...state, isLoading: true };
      case AuthType.NOTIFICATION_READ_SUCCESS:
        return { ...state, isLoading: false };
      case AuthType.NOTIFICATION_READ_FAIL:
        return { ...state, isLoading: false };
      case AuthType.GET_COUNTRY:
        return { ...state, countryCode: action.payload.data };
      case AuthType.USER_REGISTER_SUCCESS:
        return { ...state, registerLoading: false };
      case AuthType.USER_OTP_SUCCESS:
        return { ...state, registerLoading: false };
      default:
        return state;
    }
  }
);
