import {
  Box,
  IconButton,
  useTheme,
  Typography,
  Divider,
  Grid,
  useMediaQuery,
  Link,
  Button,
  Skeleton,
  Badge
} from "@mui/material";
import { tokens } from "../../theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { logout, notificationList, notificationRead } from "../login/redux/action";
import { useState } from "react";
import ChangePassword from "../login/ConfirmPassword";
import LogoutDialog from "../login/LogoutDialog";
import { useNavigate } from "react-router-dom";
import {
  BellFillIcon,
  ChangePasswordIcon,
  EmptyBoxIcon,
  LogoutIcon,
  MenuIcon,
  UserFillIcon
} from "../../components/Icons";
import { getAgeGroup } from "../create-ticket/redux/action";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { countryFilter, dateFormat } from "../../utils/custom";
// import DarkModeIcon from "@mui/icons-material/DarkMode";

export const TopBar = ({ handleToggleSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isRead, setIsRead] = React.useState([]);
  const [notificationMenu, setNotificationMenu] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotifyMenu = Boolean(notificationMenu);
  const dispatch = useDispatch();
  // const colorMode = React.useContext(ColorModeContext);

  const { notifications, isNotificationLoading } = useSelector((state) => state.auth);
  const { user } = useSelector(
    (state) => ({
      user: state && state.auth.user
    }),
    shallowEqual
  );
  const NOTIFICATION_ACCESS = user.userType !== "checker" || user.userType !== "window";

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClickLogoutOpen = () => {
    setOpenLogout(true);
  };
  const handleLogoutClose = () => {
    setOpenLogout(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenDialog(false);
  };
  const onHandleLogout = async () => {
    await dispatch(logout());
    await dispatch(getAgeGroup());
  };
  const notifyMenu = (event) => {
    NOTIFICATION_ACCESS && dispatch(notificationList());
    setNotificationMenu(event.currentTarget);
  };
  const closeNotifyMenu = () => {
    setNotificationMenu(null);
  };

  function linksOfNotificationModule({ moduleName = null, moduleId = "", description = "" }) {
    switch (moduleName) {
      case "artist":
        return { name: "artist", link: `/artists`, disable: false };
      case "organizer":
        return { name: "organizer", link: `/organizers/${moduleId}`, disable: false };
      case "support":
        return { name: "support", link: `/support/${moduleId}`, disable: false };
      case "settlement":
        // return { name: "settlement", link: `/event/${description.match(/Event ID: (\w+)/)[1]}/payment?manage=true` };
        return {
          name: "settlement",
          link:
            description?.split(" ")?.length > 0 && description?.split(" ")[0].includes("E")
              ? `/event/${description?.split(" ")[0]}/payment?manage=true`
              : "#",
          disable: description?.split(" ")?.length > 0 && description?.split(" ")[0].includes("E") ? false : true
        };
      case "bankDetail":
        return { name: "bankDetail", link: user.userType !== "admin" ? `/profile` : `/organizers/${moduleId}` };
      default:
        return { name: "", link: "#", disable: true };
    }
  }
  return (
    <>
      <Box p={2} className="topBar" sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
        <Grid container spacing={gridSpacing} flexWrap="wrap-reverse" alignItems="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              sx={{ alignItems: "center", justifyContent: "flex-end", gap: "8px" }}
              className="responsive-head"
            >
              <IconButton className="responsiveMenuIcon" onClick={() => handleToggleSidebar(true)}>
                <MenuIcon width="25px" height="25px" color="#757575" />
              </IconButton>
              {/* {user.userType === "organizer" && <CountrySelectBox />} */}
              {/* {user?.userType === "admin" && (
                <Box
                  sx={[
                    {
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "8px",
                      ml: 1,
                      p: 1,
                      cursor: "pointer",
                      borderRadius: "4px",
                      background: "rgba(0, 0, 0, 0.04)",
                      border: "1px solid #f5f5f5",
                      transition: "0.5s all"
                    },
                    {
                      "&:hover": {
                        border: "1px dashed #757575",
                        background: "transparent"
                      }
                    }
                  ]}
                  onClick={colorMode.toggleColorMode}
                >
                  <DarkModeIcon width="25px" height="25px" color="#757575" />
                </Box>
              )} */}
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4px" }}>
                {NOTIFICATION_ACCESS && (
                  <Box
                    sx={[
                      {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "8px",
                        ml: 1,
                        p: 1,
                        cursor: "pointer",
                        borderRadius: "4px",
                        background: "rgba(0, 0, 0, 0.04)",
                        border: "1px solid #f5f5f5",
                        transition: "0.5s all"
                      },
                      {
                        "&:hover": {
                          border: "1px dashed #757575",
                          background: "transparent"
                        }
                      }
                    ]}
                    onClick={notifyMenu}
                  >
                    <Badge
                      badgeContent={notifications?.count ?? user.notificationCount}
                      invisible={notifications?.count <= 0 ?? user?.notificationCount <= 0}
                      max={10}
                      className="notificationBadge"
                      // color="primary"
                    >
                      <BellFillIcon width="25px" height="25px" color="#757575" />
                    </Badge>
                  </Box>
                )}
                <Box
                  sx={[
                    {
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "8px",
                      ml: 1,
                      p: 1,
                      cursor: "pointer",
                      borderRadius: "4px",
                      background: "rgba(0, 0, 0, 0.04)",
                      border: "1px solid #f5f5f5",
                      transition: "0.5s all"
                    },
                    {
                      "&:hover": {
                        border: "1px dashed #757575",
                        background: "transparent"
                      }
                    }
                  ]}
                  onClick={handleClick}
                >
                  <UserFillIcon width="25px" height="25px" color="#757575" />
                  <Typography variant="h5" color="#757575" fontWeight="600" className="hideInResponsive">
                    {user.firstName} {user.lastName}
                  </Typography>
                </Box>
              </Box>

              <Menu
                className="userMenu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <Box sx={{ padding: "6px 16px" }}>
                  <Typography
                    variant="h4"
                    color="initial"
                    sx={{
                      fontWeight: "bold",
                      color: colors.grey[100],
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      overflow: "auto"
                    }}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                  <Typography
                    color={colors.grey[500]}
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      overflow: "auto"
                    }}
                  >
                    {countryFilter(user.countryCode).phoneCode} {user.phone}
                  </Typography>

                  {/* <Typography
                    color={colors.grey[500]}
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      overflow: "auto",
                      fontSize: "14px"
                    }}
                  >
                    {user.email}
                  </Typography> */}
                </Box>
                <Divider sx={{ my: 1 }} />
                <MenuItem
                  disabled={!NOTIFICATION_ACCESS}
                  sx={{ color: colors.grey[100], gap: "10px" }}
                  onClick={() => {
                    navigate("/profile");
                    handleClose();
                  }}
                >
                  <UserFillIcon width="25px" height="25px" color="#141414" />
                  User Profile
                </MenuItem>
                <MenuItem
                  disabled={!NOTIFICATION_ACCESS}
                  sx={{ color: colors.grey[100], gap: "10px" }}
                  onClick={handleClickOpen}
                  handleClose={handleClose}
                >
                  <ChangePasswordIcon width="25px" height="25px" color="#141414" />
                  Change Password
                </MenuItem>

                <MenuItem sx={{ color: colors.grey[100], gap: "10px" }} onClick={() => setOpenLogout(true)}>
                  <LogoutIcon width="25px" height="25px" color="#141414" />
                  Logout
                </MenuItem>
              </Menu>

              {NOTIFICATION_ACCESS && (
                <Menu
                  className="notifyMenu"
                  id="basic-menu"
                  anchorEl={notificationMenu}
                  open={openNotifyMenu}
                  onClose={closeNotifyMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Typography sx={{ color: colors.primary[800], padding: "0px 16px", fontWeight: "bold" }}>
                    Notification Center
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                  <Box sx={{ maxHeight: "350px", overflowY: "auto" }}>
                    {isNotificationLoading ? (
                      [1, 2, 3, 4, 5, 6].map((m) => {
                        return (
                          <Box
                            key={m}
                            sx={{
                              display: "flex",
                              alignItems: "self-start",
                              paddingInline: "16px",
                              gap: "8px",
                              marginBlock: "10px"
                            }}
                          >
                            <Box>
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{ borderRadius: "5px" }}
                                width={22}
                                height={22}
                              />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                width="100%"
                                sx={{ marginBottom: "5px" }}
                              />
                              <Skeleton animation="wave" variant="rectangular" width="100%" height={"30px"} />
                            </Box>
                          </Box>
                        );
                      })
                    ) : notifications && notifications?.notification?.length > 0 ? (
                      notifications.notification.map((item, i) => {
                        return (
                          <MenuItem key={i} sx={{ color: colors.grey[100], gap: "10px" }}>
                            <Box className="organiserNotify" sx={{ width: "100%", float: "left" }}>
                              {item?.isReadFlag && (
                                <Box sx={{ width: "10%", float: "left", textAlign: "left" }}>
                                  <input
                                    type="checkbox"
                                    checked={isRead.findIndex((x) => x === item?._id) > -1}
                                    onChange={() => {
                                      if (isRead.findIndex((x) => x === item?._id) > -1) {
                                        setIsRead([...isRead.filter((x) => x !== item?._id)]);
                                      } else {
                                        setIsRead([...isRead, item?._id]);
                                      }
                                    }}
                                  />
                                </Box>
                              )}
                              <Link
                                // target={"_blank"}
                                // onClick={() => {
                                //   navigate(linksOfNotificationModule(item).link);
                                // }}
                                href={linksOfNotificationModule(item).link}
                                sx={{ width: "90%", float: "left", textDecoration: "none" }}
                              >
                                <Box className="notifyContent">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "flex-start",
                                      gap: "16px"
                                    }}
                                  >
                                    <Typography
                                      variant="h5"
                                      color={colors.primary[900]}
                                      sx={{
                                        fontWeight: "bold",
                                        maxWidth: "185px",
                                        textOverflow: "ellipsis",
                                        width: "100%",
                                        overflow: "hidden"
                                      }}
                                    >
                                      {item?.title}
                                    </Typography>
                                    <Typography sx={{ color: colors.grey[500], fontSize: "12px" }}>
                                      {moment(item?.createdAt).format(dateFormat)}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    className="twoLineEllipse"
                                    sx={{ color: colors.grey[500], fontSize: "14px", whiteSpace: "normal" }}
                                    dangerouslySetInnerHTML={{ __html: item?.description }}
                                  />
                                </Box>
                              </Link>
                            </Box>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <Box textAlign="center" p="16px">
                        <Box sx={{ filter: "grayscale(100%)" }}>
                          <EmptyBoxIcon width="80px" height="80px" />
                        </Box>
                        <Typography variant="h4">We couldn't find any notification.</Typography>
                      </Box>
                    )}
                  </Box>
                  <Divider sx={{ mb: 1 }} />
                  <Button
                    disabled={isRead.length === 0}
                    variant="link"
                    sx={{ fontWeight: "bold", color: colors.primary[900] }}
                    onClick={() => {
                      dispatch(notificationRead({ updateList: isRead })).then(async (data) => {
                        if (data.type === "NOTIFICATION_READ_SUCCESS" && data?.payload?.status) {
                          dispatch(notificationList());
                          setIsRead([]);
                        }
                        if (data.type === "NOTIFICATION_READ_FAIL") {
                          toast.error(data.payload.message);
                        }
                      });
                    }}
                  >
                    Mark as Read
                  </Button>
                </Menu>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ChangePassword openDIalog={openDialog} handleClose={handleClose} onClick={handleClickOpen} />
      <LogoutDialog
        openLogout={openLogout}
        handleClickLogoutOpen={handleClickLogoutOpen}
        handleLogoutClose={handleLogoutClose}
        onHandleLogout={onHandleLogout}
      />
    </>
  );
};
