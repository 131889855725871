export const DashboardType = {
  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAIL: "GET_DASHBOARD_FAIL"
};

export const NewDashboard = {
  GET_NEW_DASHBOARD_ONE_REQUEST: "GET_NEW_DASHBOARD_ONE_REQUEST",
  GET_NEW_DASHBOARD_ONE_SUCCESS: "GET_NEW_DASHBOARD_ONE_SUCCESS",
  GET_NEW_DASHBOARD_ONE_FAIL: "GET_NEW_DASHBOARD_ONE_FAIL",
  GET_NEW_DASHBOARD_TWO_REQUEST: "GET_NEW_DASHBOARD_TWO_REQUEST",
  GET_NEW_DASHBOARD_TWO_SUCCESS: "GET_NEW_DASHBOARD_TWO_SUCCESS",
  GET_NEW_DASHBOARD_TWO_FAIL: "GET_NEW_DASHBOARD_TWO_FAIL",
  GET_NEW_DASHBOARD_THREE_REQUEST: "GET_NEW_DASHBOARD_THREE_REQUEST",
  GET_NEW_DASHBOARD_THREE_SUCCESS: "GET_NEW_DASHBOARD_THREE_SUCCESS",
  GET_NEW_DASHBOARD_THREE_FAIL: "GET_NEW_DASHBOARD_THREE_FAIL"
};
