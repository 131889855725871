import { EventListType } from "../../events/redux/type";
import { EventType } from "./type";

// Auth intial state
const initialAuthState = {
  events: [],
  organizerDropDown: [],
  event: null,
  isLoading: true,
  error: null,
  categories: [],
  languages: [],
  artists: [],
  terms: [],
  toggledId: false,
  singleEvent: null,
  singleEventLoading: false,
  isUpdateEventLoader: false,
  isCreateLoading: false,
  organizersList: [],
  organizerLoading: true
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case EventType.CREATE_EVENT_REQUEST:
      return { ...state, isCreateLoading: true };
    case EventType.CREATE_EVENT_SUCCESS:
      return { ...state, isCreateLoading: false, user: action.payload.data };
    case EventType.CREATE_EVENT_FAIL:
      return { ...state, isCreateLoading: false, error: action.error };
    case EventType.UPDATE_EVENT_REQUEST:
      return { ...state, isLoading: true, isUpdateEventLoader: true };
    case EventType.UPDATE_EVENT_SUCCESS:
      return { ...state, isLoading: false, isUpdateEventLoader: false };
    case EventType.UPDATE_EVENT_FAIL:
      return { ...state, isLoading: false, error: action.error, isUpdateEventLoader: false };
    case EventType.GET_EVENT_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_EVENT_SUCCESS:
      return { ...state, isLoading: false };
    case EventType.GET_EVENT_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_CATEGORY_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, categories: action.payload.data };
    case EventType.GET_CATEGORY_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_LANGAUGES_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_LANGAUGES_SUCCESS:
      return { ...state, isLoading: false, languages: action.payload.data };
    case EventType.GET_LANGAUGES_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_ARTISTS_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_ARTISTS_SUCCESS:
      return { ...state, isLoading: false, artists: action.payload.data };
    case EventType.GET_ARTISTS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_ALL_EVENTS_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_ALL_EVENTS_SUCCESS:
      return { ...state, isLoading: false, events: action.payload.data };
    case EventType.GET_ALL_EVENTS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case EventType.GET_TERMS_CONDITION_REQUEST:
      return { ...state, isLoading: true };
    case EventType.GET_TERMS_CONDITION_SUCCESS:
      return { ...state, isLoading: false, terms: action.payload.data };
    case EventType.GET_TERMS_CONDITION_FAIL:
    case EventType.GET_DROPDOWN_REQUEST:
      return { ...state, organizerDropDownLoading: true };
    case EventType.GET_DROPDOWN_SUCCESS:
      return { ...state, organizerDropDownData: action.payload.data, organizerDropDownLoading: false };
    case EventType.GET_TOGGLED_ID_SUCCESS:
      return { ...state, isLoading: false, toggledId: state.toggledId ? false : true };
    case EventType.GET_DROPDOWN_FAIL:
    case EventType.GET_SINGLE_EVENT_REQUEST:
      return { ...state, singleEventLoading: true };
    case EventType.GET_SINGLE_EVENT_SUCCESS:
      return { ...state, singleEventLoading: false, singleEvent: action.payload.data };
    case EventType.GET_SINGLE_EVENT_FAIL:
      return { ...state, singleEventLoading: false, error: action.error };
    case EventType.GET_ORGANIZERS_LIST_REQUEST:
      return { ...state, organizerLoading: true };
    case EventType.GET_ORGANIZERS_LIST_SUCCESS:
      return { ...state, organizerLoading: false, organizersList: action.payload.data };
    case EventType.GET_ORGANIZERS_LIST_FAIL:
      return { ...state, organizerLoading: false, error: action.error };
    case EventListType.ADD_CHARGES_SUCCESS:
      return {
        ...state,
        isAddLoading: false,
        events: {
          ...state.events,
          allEvents: state.events.allEvents?.filter((x) => {
            if (x._id === action.payload?.eventId) {
              x.serviceAmount = action.payload?.serviceAmount;
              x.minServiceAmount = action.payload?.minServiceAmount;
              x.convenienceFee = action.payload?.convenienceFee;
            }
            return x;
          })
        }
      };
    default:
      return state;
  }
};
