export const ReportType = {
  // repots constants
  GET_REPORTS_REQUEST: "GET_REPORTS_REQUEST",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAIL: "GET_REPORTS_FAIL",

  // sales repots constants
  GET_SALES_REPORTS_REQUEST: "GET_SALES_REPORTS_REQUEST",
  GET_SALES_REPORTS_SUCCESS: "GET_SALES_REPORTS_SUCCESS",
  GET_SALES_REPORTS_FAIL: "GET_SALES_REPORTS_FAIL",

  // send massage constants
  SEND_MASSAGES_REQUEST: "SEND_MASSAGES_REQUEST",
  SEND_MASSAGES_SUCCESS: "SEND_MASSAGES_SUCCESS",
  SEND_MASSAGES_FAIL: "SEND_MASSAGES_FAIL",

  // send mail constants
  SEND_MAIL_REQUEST: "SEND_MAIL_REQUEST",
  SEND_MAIL_SUCCESS: "SEND_MAIL_SUCCESS",
  SEND_MAIL_FAIL: "SEND_MAIL_FAIL",

  // send refund constants
  ADD_REFUND_REQUEST: "ADD_REFUND_REQUEST",
  ADD_REFUND_SUCCESS: "ADD_REFUND_SUCCESS",
  ADD_REFUND_FAIL: "ADD_REFUND_FAIL",

  // payment details constants
  GET_PAYMENT_DETAILS_REQUEST: "GET_PAYMENT_DETAILS_REQUEST",
  GET_PAYMENT_DETAILS_SUCCESS: "GET_PAYMENT_DETAILS_SUCCESS",
  GET_PAYMENT_DETAILS_FAIL: "GET_PAYMENT_DETAILS_FAIL",

  // Notification Send
  SEND_NOTIFICATION_REQUEST: "SEND_NOTIFICATION_REQUEST",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAIL: "SEND_NOTIFICATION_FAIL"
};
