export const PaymentType = {
  // payment list
  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAIL: "GET_PAYMENT_FAIL",

  // add payment list
  ADD_PAYMENT_REQUEST: "ADD_PAYMENT_REQUEST",
  ADD_PAYMENT_SUCCESS: "ADD_PAYMENT_SUCCESS",
  ADD_PAYMENT_FAIL: "ADD_PAYMENT_FAIL",

  // get single payment list
  GET_SINGLE_PAYMENT_REQUEST: "GET_SINGLE_PAYMENT_REQUEST",
  GET_SINGLE_PAYMENT_SUCCESS: "GET_SINGLE_PAYMENT_SUCCESS",
  GET_SINGLE_PAYMENT_FAIL: "GET_SINGLE_PAYMENT_FAIL",

  // edit payment list
  EDIT_PAYMENT_REQUEST: "EDIT_PAYMENT_REQUEST",
  EDIT_PAYMENT_SUCCESS: "EDIT_PAYMENT_SUCCESS",
  EDIT_PAYMENT_FAIL: "EDIT_PAYMENT_FAIL"
};
