export const OganizerType = {
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAIL: "GET_CUSTOMERS_FAIL",
  GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
  GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",
  GET_CUSTOMER_FAIL: "GET_CUSTOMER_FAIL",
  GET_ORGANIZER_DASHBOARD_REQUEST: "GET_ORGANIZER_DASHBOARD_REQUEST",
  GET_ORGANIZER_DASHBOARD_SUCCESS: "GET_ORGANIZER_DASHBOARD_SUCCESS",
  GET_ORGANIZER_DASHBOARD_FAIL: "GET_ORGANIZER_DASHBOARD_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  GET_STATE_DROPDOWN_REQUEST: "GET_STATE_DROPDOWN_REQUEST",
  GET_STATE_DROPDOWN_SUCCESS: "GET_STATE_DROPDOWN_SUCCESS",
  GET_STATE_DROPDOWN_FAIL: "GET_STATE_DROPDOWN_FAIL",
  GET_CITY_DROPDOWN_REQUEST: "GET_CITY_DROPDOWN_REQUEST",
  GET_CITY_DROPDOWN_SUCCESS: "GET_CITY_DROPDOWN_SUCCESS",
  GET_CITY_DROPDOWN_FAIL: "GET_CITY_DROPDOWN_FAIL"
};
