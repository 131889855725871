export const ArtistType = {
  GET_ARTISTS_LIST_REQUEST: "GET_ARTISTS_LIST_REQUEST",
  GET_ARTISTS_LIST_SUCCESS: "GET_ARTISTS_LIST_SUCCESS",
  GET_ARTISTS_LIST_FAIL: "GET_ARTISTS_LIST_FAIL",
  ADD_ARTIST_REQUEST: "ADD_ARTISTS_REQUEST",
  ADD_ARTIST_SUCCESS: "ADD_ARTISTS_SUCCESS",
  ADD_ARTIST_FAIL: "ADD_ARTISTS_FAIL",
  UPDATE_ARTIST_REQUEST: "UPDATE_ARTISTS_REQUEST",
  UPDATE_ARTIST_SUCCESS: "UPDATE_ARTISTS_SUCCESS",
  UPDATE_ARTIST_FAIL: "UPDATE_ARTISTS_FAIL",
  DELETE_ARTIST_REQUEST: "DELETE_ARTISTS_REQUEST",
  DELETE_ARTIST_SUCCESS: "DELETE_ARTISTS_SUCCESS",
  DELETE_ARTIST_FAIL: "DELETE_ARTISTS_FAIL"
};
