export const SupportType = {
  GET_SUPPORT_LIST_REQUEST: "GET_SUPPORT_LIST_REQUEST",
  GET_SUPPORT_LIST_SUCCESS: "GET_SUPPORT_LIST_SUCCESS",
  GET_SUPPORT_LIST_FAIL: "GET_SUPPORT_LIST_FAIL",
  GET_SINGLE_SUPPORT_REQUEST: "GET_SINGLE_SUPPORT_REQUEST",
  GET_SINGLE_SUPPORT_SUCCESS: "GET_SINGLE_SUPPORT_SUCCESS",
  GET_SINGLE_SUPPORT_FAIL: "GET_SINGLE_SUPPORT_FAIL",
  GET_DASHBOARD_SUPPORT_REQUEST: "GET_DASHBOARD_SUPPORT_REQUEST",
  GET_DASHBOARD_SUPPORT_SUCCESS: "GET_DASHBOARD_SUPPORT_SUCCESS",
  GET_DASHBOARD_SUPPORT_FAIL: "GET_DASHBOARD_SUPPORT_FAIL",
  ADD_MESSAGE_SUPPORT_REQUEST: "ADD_MESSAGE_SUPPORT_REQUEST",
  ADD_MESSAGE_SUPPORT_SUCCESS: "ADD_MESSAGE_SUPPORT_SUCCESS",
  ADD_MESSAGE_SUPPORT_FAIL: "ADD_MESSAGE_SUPPORT_FAIL"
};
