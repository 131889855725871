import React from "react";
import "./style.css";

export default function GlobalLoader({ className = "", ...rest }) {
  return (
    <div className={className ? className : `globalLoader ${className}`.trim()} {...rest}>
      <div className="loaderBack">
        <div className="loader"></div>
      </div>
    </div>
  );
}
