import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { ColorModeContext, useMode } from "./theme";
import { PrivateRoute } from "./PrivateRoute";
import { useEffect } from "react";
import { getAgeGroup } from "./pages/create-ticket/redux/action";
import { useDispatch } from "react-redux";

function App() {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAgeGroup());
  });
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrivateRoute />
        <ToastContainer theme="colored" autoClose={3000} hideProgressBar />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
