export const SponserType = {
  // list sponsers/promoter
  GET_SPONSERS_LIST_REQUEST: "GET_SPONSERS_LIST_REQUEST",
  GET_SPONSERS_LIST_SUCCESS: "GET_SPONSERS_LIST_SUCCESS",
  GET_SPONSERS_LIST_FAIL: "GET_SPONSERS_LIST_FAIL",

  // add sponsers/promoter
  ADD_SPONSERS_REQUEST: "ADD_SPONSERS_REQUEST",
  ADD_SPONSERS_SUCCESS: "ADD_SPONSERS_SUCCESS",
  ADD_SPONSERS_FAIL: "ADD_SPONSERS_FAIL",

  // edit sponsers/promoter
  EDIT_SPONSERS_REQUEST: "EDIT_SPONSERS_REQUEST",
  EDIT_SPONSERS_SUCCESS: "EDIT_SPONSERS_SUCCESS",
  EDIT_SPONSERS_FAIL: "EDIT_SPONSERS_FAIL",

  // ticket-list sponsers/promoter
  GET_SPONSERS_TICKETS_LIST_REQUEST: "GET_SPONSERS_TICKETS_LIST_REQUEST",
  GET_SPONSERS_TICKETS_LIST_SUCCESS: "GET_SPONSERS_TICKETS_LIST_SUCCESS",
  GET_SPONSERS_TICKETS_LIST_FAIL: "GET_SPONSERS_TICKETS_LIST_FAIL",

  // single sponsers/promoter
  GET_SINGLE_SPONSOR_REQUEST: "GET_SINGLE_SPONSOR_REQUEST",
  GET_SINGLE_SPONSOR_SUCCESS: "GET_SINGLE_SPONSOR_SUCCESS",
  GET_SINGLE_SPONSOR_FAIL: "GET_SINGLE_SPONSOR_FAIL",

  // ticket sponsers/promoter
  GET_TICKET_SPONSOR_REQUEST: "GET_TICKET_SPONSOR_REQUEST",
  GET_TICKET_SPONSOR_SUCCESS: "GET_TICKET_SPONSOR_SUCCESS",
  GET_TICKET_SPONSOR_FAIL: "GET_TICKET_SPONSOR_FAIL",

  // ticket-list sponsers/promoter
  GET_SPONSERS_TICKETS_LIST_REQUEST1: "GET_SPONSERS_TICKETS_LIST_REQUEST1",
  GET_SPONSERS_TICKETS_LIST_SUCCESS1: "GET_SPONSERS_TICKETS_LIST_SUCCESS1",
  GET_SPONSERS_TICKETS_LIST_FAIL1: "GET_SPONSERS_TICKETS_LIST_FAIL1"
};
