import { DiscountType } from "./type";

//discount intial state
const initialAuthState = {
  error: null,
  discountList: null,
  isDiscountLoading: true,
  discount: null,
  singleDiscountLoading: true,
  addDiscountLoading: false,
  updateDiscountLoading:false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case DiscountType.GET_DISCOUNT_REQUEST:
      return { ...state, isDiscountLoading: true };
    case DiscountType.GET_DISCOUNT_SUCCESS:
      return { ...state, isDiscountLoading: false, discountList: action.payload.data };
    case DiscountType.GET_DISCOUNT_FAIL:
      return { ...state, isDiscountLoading: false, error: action.error };

    case DiscountType.GET_SINGLE_DISCOUNT_REQUEST:
      return { ...state, singleDiscountLoading: true };
    case DiscountType.GET_SINGLE_DISCOUNT_SUCCESS:
      return { ...state, singleDiscountLoading: false, discount: action.payload.data };
    case DiscountType.GET_SINGLE_DISCOUNT_FAIL:
      return { ...state, singleDiscountLoading: false, error: action.error };

    case DiscountType.ADD_DISCOUNT_REQUEST:
      return { ...state, addDiscountLoading: true };
    case DiscountType.ADD_DISCOUNT_SUCCESS:
      return { ...state, addDiscountLoading: false };
    case DiscountType.ADD_DISCOUNT_FAIL:
      return { ...state, addDiscountLoading: false, error: action.error };

    case DiscountType.UPDATE_DISCOUNT_REQUEST:
      return { ...state, updateDiscountLoading: true };
    case DiscountType.UPDATE_DISCOUNT_SUCCESS:
      return { ...state, updateDiscountLoading: false };
    case DiscountType.UPDATE_DISCOUNT_FAIL:
      return { ...state, updateDiscountLoading: false, error: action.error };


    default:
      return state;
  }
};
