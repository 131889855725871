export const WindowUser = {
  GET_WINDOW_USER_LIST_REQUEST: "GET_WINDOW_USER_LIST_REQUEST",
  GET_WINDOW_USER_LIST_SUCCESS: "GET_WINDOW_USER_LIST_SUCCESS",
  GET_WINDOW_USER_LIST_FAIL: "GET_WINDOW_USER_LIST_FAIL",

  ADD_WINDOW_USER_REQUEST: "ADD_WINDOW_USER_REQUEST",
  ADD_WINDOW_USER_SUCCESS: "ADD_WINDOW_USER_SUCCESS",
  ADD_WINDOW_USER_FAIL: "ADD_WINDOW_USER_FAIL",

  UPDATE_WINDOW_USER_REQUEST: "UPDATE_WINDOW_USER_REQUEST",
  UPDATE_WINDOW_USER_SUCCESS: "UPDATE_WINDOW_USER_SUCCESS",
  UPDATE_WINDOW_USER_FAIL: "UPDATE_WINDOW_USER_FAIL",

  DELETE_WINDOW_USER_REQUEST: "DELETE_WINDOW_USER_REQUEST",
  DELETE_WINDOW_USER_SUCCESS: "DELETE_WINDOW_USER_SUCCESS",
  DELETE_WINDOW_USER_FAIL: "DELETE_WINDOW_USER_FAIL",

  GET_TICKET_LIST_REQUEST: "GET_TICKET_LIST_REQUEST",
  GET_TICKET_LIST_SUCCESS: "GET_TICKET_LIST_SUCCESS",
  GET_TICKET_LIST_FAIL: "GET_TICKET_LIST_FAIL",

  GET_TICKET_ITEM_REQUEST: "GET_TICKET_ITEM_REQUEST",
  GET_TICKET_ITEM_SUCCESS: "GET_TICKET_ITEM_SUCCESS",
  GET_TICKET_ITEM_FAIL: "GET_TICKET_ITEM_FAIL",

  UPDATE_WINDOW_USER_STATUS_REQUEST: "UPDATE_WINDOW_USER_STATUS_REQUEST",
  UPDATE_WINDOW_USER_STATUS_SUCCESS: "UPDATE_WINDOW_USER_STATUS_SUCCESS",
  UPDATE_WINDOW_USER_STATUS_FAIL: "UPDATE_WINDOW_USER_STATUS_FAIL",

  BUY_TICKET_REQUEST: "BUY_TICKET_REQUEST",
  BUY_TICKET_SUCCESS: "BUY_TICKET_SUCCESS",
  BUY_TICKET_FAIL: "BUY_TICKET_FAIL",

  GET_USER_FROM_MOBILE_REQUEST: "GET_USER_FROM_MOBILE_REQUEST",
  GET_USER_FROM_MOBILE_SUCCESS: "GET_USER_FROM_MOBILE_SUCCESS",
  GET_USER_FROM_MOBILE_FAIL: "GET_USER_FROM_MOBILE_FAIL",

  GET_WINDOW_REPORT_REQUEST: "GET_WINDOW_REPORT_REQUEST",
  GET_WINDOW_REPORT_SUCCESS: "GET_WINDOW_REPORT_SUCCESS",
  GET_WINDOW_REPORT_FAIL: "GET_WINDOW_REPORT_FAIL"
};
