import React, { Suspense, lazy, useState } from "react";
import { Box } from "@mui/system";
import { Routes, Route, Navigate } from "react-router-dom";
import { TopBar } from "./Topbar";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import GlobalLoader from "../../components/GlobalLoader";

const WindowUser = lazy(() => import("../windowUser"));
const DecryptComponent = lazy(() => import("../../components/DecryptComponent"));
const NewDashboard = lazy(() => import("../dashboard/NewDashboard"));
const AutoLoginScreen = lazy(() => import("../login/AutoLoginScreen"));
const Transaction = lazy(() => import("../event-transaction"));
const AgeGroup = lazy(() => import("../AgeGroup"));
const Events = lazy(() => import("../events"));
const CreateEvent = lazy(() => import("../event-create/CreateEvent"));
const CheckIn = lazy(() => import("../check-in"));
const CheckInUser = lazy(() => import("../check-in/checkin"));
const CheckOutUser = lazy(() => import("../check-in/checkout"));
const ProfileScreen = lazy(() => import("../login/ProfileScreen"));
const Organizers = lazy(() => import("../organizers"));
const Artists = lazy(() => import("../artists"));
const OrganizerDashboard = lazy(() => import("../organizers/Dashboard"));
const AgeReports = lazy(() => import("../age-report"));
const CheckinReport = lazy(() => import("../reports-checkin"));
const Reports = lazy(() => import("../reports"));
const Customers = lazy(() => import("../customers"));
const TermCondition = lazy(() => import("../termCondition"));
const CustomerDashboard = lazy(() => import("../customers/Dashboard"));
const Support = lazy(() => import("../admin-support"));
const SupportView = lazy(() => import("../admin-support/SupportView"));
const Sponser = lazy(() => import("../sponsor"));
const ViewSponsor = lazy(() => import("../sponsor/ViewSponsor"));
// const Dashboard = lazy(() => import("../dashboard"));
const AddDiscount = lazy(() => import("../discount/AddDiscount"));
const Discount = lazy(() => import("../discount"));
const AddSponsor = lazy(() => import("../sponsor/AddSponsor"));
const Payment = lazy(() => import("../payment"));
const BookTicket = lazy(() => import("../bookTicket"));
const BookTicketList = lazy(() => import("../bookTicketList"));

export default function Layout() {
  const [isSidebar, setIsSidebar] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const ADMIN = user.userType === "admin",
    ORGANIZER = user.userType === "organizer",
    CHECKER = user.userType === "checker",
    WINDOW = user.userType === "window";
  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%", height: "100%" }}>
      <Box className={`${toggled ? "toggled" : ""}`}>
        <Sidebar
          isSidebar={isSidebar}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
      </Box>
      <Box
        sx={{ minWidth: 0, flex: "0 1 auto", overflowY: "auto" }}
        width="100%"
        height="100%"
        className="respBottomSpace"
      >
        <TopBar setIsSidebar={setIsSidebar} handleToggleSidebar={handleToggleSidebar} />

        <Suspense fallback={<GlobalLoader className={"fit_to_content"} />}>
          <Routes>
            {/* //user/organizer module routes */}
            {!CHECKER && !WINDOW && (
              <>
                <Route exact path="/dashboard" element={<NewDashboard />} />
                {/* <Route exact path="/dashboard/new" element={<Dashboard />} /> */}
                <Route exact path="/" element={<NewDashboard />} />
                <Route exact path="/event/:id/report" element={<Reports />} />
                <Route exact path="/event/:id/age-report" element={<AgeReports />} />
                <Route exact path="/event/:id/sponser" element={<Sponser />} />
                <Route exact path="/event/:id/discount" element={<Discount />} />
                <Route exact path="/event/:id/discount/add" element={<AddDiscount />} />
                <Route exact path="/event/:id/discount/add/:discountId" element={<AddDiscount />} />
                <Route exact path="/event/:id/sponser/add" element={<AddSponsor />} />
                <Route exact path="/event/:id/sponser/add/:sponserId" element={<AddSponsor />} />
                <Route exact path="/event/:id/sponser/:sponserId" element={<ViewSponsor />} />
                <Route exact path="/event/:id/checkin-report" element={<CheckinReport />} />
                <Route exact path="/event/:id/windowUser" element={<WindowUser />} />
              </>
            )}
            {/* //user module routes */}
            {ADMIN && (
              <>
                <Route exact path="/organizers" element={<Organizers />} />
                <Route exact path="/organizers/:id" element={<OrganizerDashboard />} />
                <Route exact path="/artists" element={<Artists />} />
                <Route exact path="/customers" element={<Customers />} />
                <Route exact path="/customers/:id" element={<CustomerDashboard />} />
                <Route exact path="/term-condition" element={<TermCondition />} />
                <Route exact path="/support" element={<Support />} />
                <Route exact path="/support/:id" element={<SupportView />} />
              </>
            )}
            {/* //organizer module routes */}
            {ORGANIZER && (
              <>
                <Route exact path="/create-event" element={<CreateEvent />} />
                <Route exact path="/create-event/:id" element={<CreateEvent />} />
              </>
            )}
            {/* //checker module routes */}
            {CHECKER && (
              <>
                <Route exact path="/" element={<Events />} />
              </>
            )}
            {WINDOW && (
              <>
                <Route exact path="/" element={<Events />} />
              </>
            )}
            {/* //all module routes */}
            <Route exact path="/transaction" element={<Transaction />} />
            <Route exact path="/age-report" element={<AgeGroup />} />
            {!WINDOW && <Route exact path="/events" element={<Events />} />}
            <Route exact path="/profile" element={<ProfileScreen />} />
            <Route exact path="/autologin/:orgId/:go" element={<AutoLoginScreen />} />
            <Route exact path="/decrypt" element={<DecryptComponent />} />
            <Route exact path="/event/:id/checkin" element={<CheckIn />} />
            <Route exact path="/event/:id/bookTicket" element={<BookTicket />} />
            <Route exact path="/event/:id/ticket-list" element={<BookTicketList />} />
            <Route exact path="/event/:id/user-checkin" element={<CheckInUser />} />
            <Route exact path="/event/:id/user-checkout" element={<CheckOutUser />} />
            <Route exact path="/event/:id/payment" element={<Payment />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
}
