export const OganizerType = {
  GET_ORGANIZERS_REQUEST: "GET_ORGANIZERS_REQUEST",
  GET_ORGANIZERS_SUCCESS: "GET_ORGANIZERS_SUCCESS",
  GET_ORGANIZERS_FAIL: "GET_ORGANIZERS_FAIL",
  GET_ORGANIZER_REQUEST: "GET_ORGANIZER_REQUEST",
  GET_ORGANIZER_SUCCESS: "GET_ORGANIZER_SUCCESS",
  GET_ORGANIZER_FAIL: "GET_ORGANIZER_FAIL",
  GET_ORGANIZER_DASHBOARD_REQUEST: "GET_ORGANIZER_DASHBOARD_REQUEST",
  GET_ORGANIZER_DASHBOARD_SUCCESS: "GET_ORGANIZER_DASHBOARD_SUCCESS",
  GET_ORGANIZER_DASHBOARD_FAIL: "GET_ORGANIZER_DASHBOARD_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  ORGANIZER_UPDATE_REQUEST: "ORGANIZER_UPDATE_REQUEST",
  ORGANIZER_UPDATE_SUCCESS: "ORGANIZER_UPDATE_SUCCESS",
  ORGANIZER_UPDATE_FAIL: "ORGANIZER_UPDATE_FAIL",
  ORGANIZER_INHOUSE_UPDATE_REQUEST: "ORGANIZER_INHOUSE_UPDATE_REQUEST",
  ORGANIZER_INHOUSE_UPDATE_FAIL: "ORGANIZER_INHOUSE_UPDATE_FAIL",
  ORGANIZER_INHOUSE_UPDATE_SUCCESS: "ORGANIZER_INHOUSE_UPDATE_SUCCESS",
  ORGANIZER_ADD_NOT_REQUEST: "ORGANIZER_ADD_NOT_REQUEST",
  ORGANIZER_ADD_NOT_FAIL: "ORGANIZER_ADD_NOT_FAIL",
  ORGANIZER_ADD_NOT_SUCCESS: "ORGANIZER_ADD_NOT_SUCCESS",
  ORGANIZER_EVENTS_REQUEST: "ORGANIZER_EVENTS_REQUEST",
  ORGANIZER_EVENTS_FAIL: "ORGANIZER_EVENTS_FAIL",
  ORGANIZER_EVENTS_SUCCESS: "ORGANIZER_EVENTS_SUCCESS"
};
