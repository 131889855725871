export const TermConditionType = {
  GET_TERM_CONDITION_LIST_REQUEST: "GET_TERM_CONDITION_LIST_REQUEST",
  GET_TERM_CONDITION_LIST_SUCCESS: "GET_TERM_CONDITION_LIST_SUCCESS",
  GET_TERM_CONDITION_LIST_FAIL: "GET_TERM_CONDITION_LIST_FAIL",
  
  ADD_TERM_CONDITION_REQUEST: "ADD_TERM_CONDITION_REQUEST",
  ADD_TERM_CONDITION_SUCCESS: "ADD_TERM_CONDITION_SUCCESS",
  ADD_TERM_CONDITION_FAIL: "ADD_TERM_CONDITION_FAIL",

  UPDATE_TERM_CONDITION_REQUEST: "UPDATE_TERM_CONDITION_REQUEST",
  UPDATE_TERM_CONDITION_SUCCESS: "UPDATE_TERM_CONDITION_SUCCESS",
  UPDATE_TERM_CONDITION_FAIL: "UPDATE_TERM_CONDITION_FAIL",

  DELETE_TERM_CONDITION_REQUEST: "DELETE_TERM_CONDITION_REQUEST",
  DELETE_TERM_CONDITION_SUCCESS: "DELETE_TERM_CONDITION_SUCCESS",
  DELETE_TERM_CONDITION_FAIL: "DELETE_TERM_CONDITION_FAIL"
};
