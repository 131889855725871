import { TermConditionType } from "./type";

// TERM_CONDITION intial state
const initialAuthState = {
  termConditionList: null,
  isLoading: true,
  error: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case TermConditionType.GET_TERM_CONDITION_LIST_REQUEST:
      return { ...state, isLoading: true };
    case TermConditionType.GET_TERM_CONDITION_LIST_SUCCESS:
      return { ...state, isLoading: false, termConditionList: action.payload.data };
    case TermConditionType.GET_TERM_CONDITION_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case TermConditionType.ADD_TERM_CONDITION_REQUEST:
      return { ...state, isLoading: true };
    case TermConditionType.ADD_TERM_CONDITION_SUCCESS:
      return { ...state, isLoading: false };
    case TermConditionType.ADD_TERM_CONDITION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case TermConditionType.UPDATE_TERM_CONDITION_REQUEST:
      return { ...state, isLoading: true };
    case TermConditionType.UPDATE_TERM_CONDITION_SUCCESS:
      return { ...state, isLoading: false };
    case TermConditionType.UPDATE_TERM_CONDITION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case TermConditionType.DELETE_TERM_CONDITION_REQUEST:
      return { ...state, isLoading: true };
    case TermConditionType.DELETE_TERM_CONDITION_SUCCESS:
      return { ...state, isLoading: false };
    case TermConditionType.DELETE_TERM_CONDITION_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
