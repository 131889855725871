export const checkIn = {
  GET_CHECKIN_LIST_REQUEST: "GET_CHECKIN_LIST_REQUEST",
  GET_CHECKIN_LIST_SUCCESS: "GET_CHECKIN_LIST_SUCCESS",
  GET_CHECKIN_LIST_FAIL: "GET_CHECKIN_LIST_FAIL",

  ADD_CHECKIN_REQUEST: "ADD_CHECKIN_REQUEST",
  ADD_CHECKIN_SUCCESS: "ADD_CHECKIN_SUCCESS",
  ADD_CHECKIN_FAIL: "ADD_CHECKIN_FAIL",

  UPDATE_CHECKIN_REQUEST: "UPDATE_CHECKIN_REQUEST",
  UPDATE_CHECKIN_SUCCESS: "UPDATE_CHECKIN_SUCCESS",
  UPDATE_CHECKIN_FAIL: "UPDATE_CHECKIN_FAIL",

  DELETE_CHECKIN_REQUEST: "DELETE_CHECKIN_REQUEST",
  DELETE_CHECKIN_SUCCESS: "DELETE_CHECKIN_SUCCESS",
  DELETE_CHECKIN_FAIL: "DELETE_CHECKIN_FAIL",

  USER_CHECKIN_REQUEST: "USER_CHECKIN_REQUEST",
  USER_CHECKIN_SUCCESS: "USER_CHECKIN_SUCCESS",
  USER_CHECKIN_FAIL: "USER_CHECKIN_FAIL",

  USER_CHECKOUT_REQUEST: "USER_CHECKOUT_REQUEST",
  USER_CHECKOUT_SUCCESS: "USER_CHECKOUT_SUCCESS",
  USER_CHECKOUT_FAIL: "USER_CHECKOUT_FAIL",

  GET_TICKET_WISE_CHECKIN_REQUEST: "GET_TICKET_WISE_CHECKIN_REQUEST",
  GET_TICKET_WISE_CHECKIN_SUCCESS: "GET_TICKET_WISE_CHECKIN_SUCCESS",
  GET_TICKET_WISE_CHECKIN_FAIL: "GET_TICKET_WISE_CHECKIN_FAIL"
};
