import { useState, useEffect, memo, Fragment } from "react";

const NoInternetConnection = ({ children }) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });
  const h1 = {
    margin: "0px",
    fontWeight: "300",
    lineHeight: "1.167",
    letterSpacing: "-0.01562em",
    fontSize: "220px"
  };
  const h4 = {
    margin: "0px",
    fontWeight: 400,
    fontSize: "2.125rem",
    lineHeight: 1.235,
    letterSpacing: "0.00735em"
  };
  const button = {
    display: "inline-block",
    outline: "none",
    cursor: "pointer",
    border: "1px solid #ffa000",
    borderRadius: "10px",
    textAlign: "left",
    verticalAlign: "top",
    padding: "0 30px",
    backgroundColor: "#00000000",
    fontSize: "14px",
    letterSpacing: "0.16px",
    minHeight: "48px",
    lineHeight: 1.29,
    color: "#ffa000",
    fontWeight: 400,
    transition:
      "background 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9)"
  };
  if (isOnline) return <Fragment>{children}</Fragment>;
  else
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "calc(100vh - 121px)",
          textAlign: "center"
        }}
      >
        <h1 style={h1}>404</h1>
        <p style={h4}>No Internet Connection. Please refresh.</p>
        <div className="no-internet">
          <button style={button} onClick={() => window.location.reload()}>
            Refresh
          </button>
        </div>
      </div>
    );
};

export default memo(NoInternetConnection);
