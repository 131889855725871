export const ReportType = {
  // checker report constants
  GET_CHECKER_REPORTS_REQUEST: "GET_CHECKER_REPORTS_REQUEST",
  GET_CHECKER_REPORTS_SUCCESS: "GET_CHECKER_REPORTS_SUCCESS",
  GET_CHECKER_REPORTS_FAIL: "GET_CHECKER_REPORTS_FAIL",
  // checker datelist constants
  GET_CHECKER_DATE_LIST_REQUEST: "GET_CHECKER_DATE_LIST_REQUEST",
  GET_CHECKER_DATE_LIST_SUCCESS: "GET_CHECKER_DATE_LIST_SUCCESS",
  GET_CHECKER_DATE_LIST_FAIL: "GET_CHECKER_DATE_LIST_FAIL"
};
