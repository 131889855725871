import { OganizerType } from "./type";

// Organizers intial state
const initialAuthState = {
  organizers: null,
  organizer: null,
  dashboard: null,
  isLoading: true,
  error: null,
  isSingleOrgnizerLoading: true,
  isNoteLoading: false,
  eventList: null,
  eventListLoad: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case OganizerType.GET_ORGANIZERS_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.GET_ORGANIZERS_SUCCESS:
      return { ...state, isLoading: false, organizers: action.payload.data };
    case OganizerType.GET_ORGANIZERS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.GET_ORGANIZER_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.GET_ORGANIZER_SUCCESS:
      return { ...state, isLoading: false, organizer: action.payload.data };
    case OganizerType.GET_ORGANIZER_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.GET_ORGANIZER_DASHBOARD_REQUEST:
      return { ...state, isSingleOrgnizerLoading: true };
    case OganizerType.GET_ORGANIZER_DASHBOARD_SUCCESS:
      return { ...state, isSingleOrgnizerLoading: false, dashboard: action.payload.data };
    case OganizerType.GET_ORGANIZER_DASHBOARD_FAIL:
      return { ...state, isSingleOrgnizerLoading: false, error: action.error };
    case OganizerType.UPDATE_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.UPDATE_STATUS_SUCCESS:
      return { ...state, isLoading: false };
    case OganizerType.UPDATE_STATUS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.ORGANIZER_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.ORGANIZER_UPDATE_SUCCESS:
      return { ...state, isLoading: false };
    case OganizerType.ORGANIZER_UPDATE_FAIL:
      return { ...state, isLoading: false };
    case OganizerType.ORGANIZER_INHOUSE_UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.ORGANIZER_INHOUSE_UPDATE_SUCCESS:
      return { ...state, isLoading: false };
    case OganizerType.ORGANIZER_INHOUSE_UPDATE_FAIL:
      return { ...state, isLoading: false };
    case OganizerType.ORGANIZER_EVENTS_REQUEST:
      return { ...state, eventListLoad: true };
    case OganizerType.ORGANIZER_EVENTS_SUCCESS:
      return { ...state, eventListLoad: false, eventList: action.payload.data };
    case OganizerType.ORGANIZER_EVENTS_FAIL:
      return { ...state, eventListLoad: false };
    default:
      return state;
  }
};
