export const EventType = {
  CREATE_EVENT_REQUEST: "CREATE_EVENT_REQUEST",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAIL: "CREATE_EVENT_FAIL",
  UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAIL: "UPDATE_EVENT_FAIL",
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAIL: "GET_EVENT_FAIL",
  GET_SINGLE_EVENT_REQUEST: "GET_SINGLE_EVENT_REQUEST",
  GET_SINGLE_EVENT_SUCCESS: "GET_SINGLE_EVENT_SUCCESS",
  GET_SINGLE_EVENT_FAIL: "GET_SINGLE_EVENT_FAIL",
  GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAIL: "GET_CATEGORY_FAIL",
  GET_LANGAUGES_REQUEST: "GET_LANGAUGES_REQUEST",
  GET_LANGAUGES_SUCCESS: "GET_LANGAUGES_SUCCESS",
  GET_LANGAUGES_FAIL: "GET_LANGAUGES_FAIL",
  GET_ARTISTS_REQUEST: "GET_ARTISTS_REQUEST",
  GET_ARTISTS_SUCCESS: "GET_ARTISTS_SUCCESS",
  GET_ARTISTS_FAIL: "GET_ARTISTS_FAIL",
  GET_ALL_EVENTS_REQUEST: "GET_ALL_EVENTS_REQUEST",
  GET_ALL_EVENTS_SUCCESS: "GET_ALL_EVENTS_SUCCESS",
  GET_ALL_EVENTS_FAIL: "GET_ALL_EVENTS_FAIL",
  GET_TERMS_CONDITION_REQUEST: "GET_TERMS_CONDITION_REQUEST",
  GET_TERMS_CONDITION_SUCCESS: " GET_TERMS_CONDITION_SUCCESS",
  GET_TERMS_CONDITION_FAIL: " GET_TERMS_CONDITION_FAIL",
  GET_DROPDOWN_REQUEST: "GET_DROPDOWN_REQUEST",
  GET_DROPDOWN_SUCCESS: "GET_DROPDOWN_SUCCESS",
  GET_DROPDOWN_FAIL: "GET_DROPDOWN_FAIL",
  GET_TOGGLED_ID_REQUEST: "GET_TOGGLED_ID_REQUEST",
  GET_TOGGLED_ID_SUCCESS: "GET_TOGGLED_ID_SUCCESS",
  GET_TOGGLED_ID_FAIL: "GET_DROPDOWN_FAIL",
  GET_ORGANIZERS_LIST_REQUEST: "GET_ORGANIZERS_LIST_REQUEST",
  GET_ORGANIZERS_LIST_SUCCESS: "GET_ORGANIZERS_LIST_SUCCESS",
  GET_ORGANIZERS_LIST_FAIL: "GET_ORGANIZERS_LIST_FAIL",
  UPLOAD_GALLERY_REQUEST: "UPLOAD_GALLERY_REQUEST",
  UPLOAD_GALLERY_SUCCESS: "UPLOAD_GALLERY_SUCCESS",
  UPLOAD_GALLERY_FAIL: "UPLOAD_GALLERY_FAIL",
  DELETE_GALLERY_REQUEST: "DELETE_GALLERY_REQUEST",
  DELETE_GALLERY_SUCCESS: "DELETE_GALLERY_SUCCESS",
  DELETE_GALLERY_FAIL: "DELETE_GALLERY_FAIL"
};
