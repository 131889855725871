import { SponserType } from "./type";

//sponsers/promoter intial state
const initialAuthState = {
  error: null,
  sponsersList: null,
  sponserTickets: null,
  singleSponser: null,
  isLoading: true,
  isTicketLoader: true,
  singleSponserLoader: true,
  isAddSponserLoader: false,
  isUpdateSponserLoader: false,
  sponserArtists:null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    // list sponsors/promoter
    case SponserType.GET_SPONSERS_LIST_REQUEST:
      return { ...state, isLoading: true };
    case SponserType.GET_SPONSERS_LIST_SUCCESS:
      return { ...state, isLoading: false, sponsersList: action.payload.data };
    case SponserType.GET_SPONSERS_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    // ticket-list sponsors/promoter
    case SponserType.GET_SPONSERS_TICKETS_LIST_REQUEST:
      return { ...state, isTicketLoader: true };
    case SponserType.GET_SPONSERS_TICKETS_LIST_SUCCESS:
      return { ...state, isTicketLoader: false, sponserTickets: action.payload.data };
    case SponserType.GET_SPONSERS_TICKETS_LIST_FAIL:
      return { ...state, isTicketLoader: false, error: action.error };
    // list sponsors/promoter
    case SponserType.GET_SINGLE_SPONSOR_REQUEST:
      return { ...state, singleSponserLoader: true };
    case SponserType.GET_SINGLE_SPONSOR_SUCCESS:
      return { ...state, singleSponserLoader: false, singleSponser: action.payload.data };
    case SponserType.GET_SINGLE_SPONSOR_FAIL:
      return { ...state, singleSponserLoader: false, error: action.error };
    // add sponsors/promoter
    case SponserType.ADD_SPONSERS_REQUEST:
      return { ...state, isAddSponserLoader: true };
    case SponserType.ADD_SPONSERS_SUCCESS:
      return { ...state, isAddSponserLoader: false };
    case SponserType.ADD_SPONSERS_FAIL:
      return { ...state, isAddSponserLoader: false, error: action.error };
    // update sponsors/promoter
    case SponserType.EDIT_SPONSERS_REQUEST:
      return { ...state, isUpdateSponserLoader: true };
    case SponserType.EDIT_SPONSERS_SUCCESS:
      return { ...state, isUpdateSponserLoader: false };
    case SponserType.EDIT_SPONSERS_FAIL:
      return { ...state, isUpdateSponserLoader: false, error: action.error };
         // ticket-list sponsors/promoter
    case SponserType.GET_SPONSERS_TICKETS_LIST_REQUEST1:
      return { ...state, isTicketLoader: true };
    case SponserType.GET_SPONSERS_TICKETS_LIST_SUCCESS1:
      return { ...state, isTicketLoader: false, sponserArtists: action.payload.data };
    case SponserType.GET_SPONSERS_TICKETS_LIST_FAIL1:
      return { ...state, isTicketLoader: false, error: action.error };

    default:
      return state;
  }
};
