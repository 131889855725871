import { API_ROOT } from "./config";
import axios from "axios";
import UseEncryption from "./UseEncryption";
import store from "../redux/store";
import { getCookie } from "./custom";

// export default JsonInterceptor;
const customAxios = (contentType, values) => {
  const axiosInstance = axios.create({
    baseURL: API_ROOT
  });
  axiosInstance.interceptors.request.use(
    async (config) => {
      const adminInfo = JSON.parse(localStorage.getItem("adminInfo"));
      if (adminInfo?.token) {
        config.headers.token = adminInfo?.token;
      }
      config.headers.platform = "admin";

      config.headers["Content-Type"] = contentType ? contentType : "application/json";
      // if (getCookie("country-code")) {
      config.headers["country-code"] = getCookie("country-code") || values;
      // }
      return config;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: "LOG_OUT" });
      }
      if (error.response && error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    async (response) => {
      return process.env.REACT_APP_ENV === "production"
        ? { ...response, data: UseEncryption(response.data) }
        : response;
    },
    (error) => {
      if (error.response && error.response) {
        if (error.response && error.response.status === 401) {
          store.dispatch({ type: "LOG_OUT" });
        }
        return Promise.reject(
          process.env.REACT_APP_ENV === "production"
            ? { ...error.response, data: UseEncryption(error.response.data) }
            : error.response
        );
      }
    }
  );
  return axiosInstance;
};

const JsonInterceptor = customAxios("application/json");
export const JsonInterceptor2 = function name(param) {
  return customAxios("application/json", param);
};

export const axiosForMultipart = customAxios("multipart/form-data");

export default JsonInterceptor;
