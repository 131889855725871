import { combineReducers } from "redux";
import * as auth from "../pages/login/redux/reducer.js";
import * as events from "../pages/event-create/redux/reducer.js";
import * as organizers from "../pages/organizers/redux/reducer.js";
import * as customers from "../pages/customers/redux/reducer.js";
import * as tickets from "../pages/create-ticket/redux/reducer.js";
import * as artists from "../pages/artists/redux/reducer.js";
import * as termCondition from "../pages/termCondition/redux/reducer.js";
import * as reports from "../pages/reports/redux/reducer.js";
import * as checkIn from "../pages/check-in/redux/reducer.js";
import * as checkerReport from "../pages/reports-checkin/redux/reducer.js";
import * as ageReport from "../pages/age-report/redux/reducer.js";
import * as support from "../pages/admin-support/redux/reducer.js";
import * as cancelled from "../pages/events/redux/reducer.js";
import * as sponser from "../pages/sponsor/redux/reducer.js";
import * as discount from "../pages/discount/redux/reducer.js";
import * as payments from "../pages/payment/redux/reducer.js";
import * as dashboard from "../pages/dashboard/redux/reducer.js";
import * as transaction from "../pages/event-transaction/redux/reducer.js";
import * as windowUser from "../pages/windowUser/redux/reducer.js";

// import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  auth: auth.reducer,
  events: events.reducer,
  organizers: organizers.reducer,
  tickets: tickets.reducer,
  artists: artists.reducer,
  customers: customers.reducer,
  termCondition: termCondition.reducer,
  reports: reports.reducer,
  checkIn: checkIn.reducer,
  checkerReport: checkerReport.reducer,
  ageReport: ageReport.reducer,
  checkInUser: checkIn.reducerOne,
  support: support.reducer,
  cancelled: cancelled.reducer,
  sponser: sponser.reducer,
  discount: discount.reducer,
  dashboard: dashboard.reducer,
  payments: payments.reducer,
  transaction: transaction.reducer,
  windowUser: windowUser.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    // for all keys defined in your persistConfig(s)
    // localStorage.setItem("adminInfo", null);
    // localStorage.setItem("adminEvent", null);
    // localStorage.setItem("eventcheckin", null);
    localStorage.clear();
    // storage.removeItem("persist:v713-demo1-auth");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
