import { OganizerType } from "./type";

// Organizers intial state
const initialAuthState = {
  customers: null,
  customer: null,
  dashboard: null,
  isLoading: true,
  error: null,
  stateLoading: false,
  states: null,
  cityLoading: false,
  city: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case OganizerType.GET_CUSTOMERS_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.GET_CUSTOMERS_SUCCESS:
      return { ...state, isLoading: false, customers: action.payload.data };
    case OganizerType.GET_CUSTOMERS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.GET_CUSTOMER_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.GET_CUSTOMER_SUCCESS:
      return { ...state, isLoading: false, customer: action.payload.data };
    case OganizerType.GET_CUSTOMER_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.GET_ORGANIZER_DASHBOARD_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.GET_ORGANIZER_DASHBOARD_SUCCESS:
      return { ...state, isLoading: false, dashboard: action.payload.data };
    case OganizerType.GET_ORGANIZER_DASHBOARD_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.UPDATE_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case OganizerType.UPDATE_STATUS_SUCCESS:
      return { ...state, isLoading: false };
    case OganizerType.UPDATE_STATUS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OganizerType.GET_STATE_DROPDOWN_REQUEST:
      return { ...state, stateLoading: true };
    case OganizerType.GET_STATE_DROPDOWN_SUCCESS:
      return { ...state, stateLoading: false, states: action.payload.data };
    case OganizerType.GET_STATE_DROPDOWN_FAIL:
      return { ...state, stateLoading: false, error: action.error };
    case OganizerType.GET_CITY_DROPDOWN_REQUEST:
      return { ...state, cityLoading: true };
    case OganizerType.GET_CITY_DROPDOWN_SUCCESS:
      return { ...state, cityLoading: false, city: action.payload.data };
    case OganizerType.GET_CITY_DROPDOWN_FAIL:
      return { ...state, cityLoading: false, error: action.error };
    default:
      return state;
  }
};
