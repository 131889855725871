import { checkIn } from "./type";

// TERM_CONDITION intial state
const initialAuthState = {
  checkIn: null,
  isLoading: true,
  error: null,
  ticketWiseCheckersList: [],
  isTicketWiseCheckersLoading: true
};
const checkInInit = {
  checkInUser: null,
  isLoading: true,
  error: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case checkIn.GET_CHECKIN_LIST_REQUEST:
      return { ...state, isLoading: true };
    case checkIn.GET_CHECKIN_LIST_SUCCESS:
      return { ...state, isLoading: false, checkIn: action.payload.data };
    case checkIn.GET_CHECKIN_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.GET_TICKET_WISE_CHECKIN_REQUEST:
      return { ...state, isTicketWiseCheckersLoading: true };
    case checkIn.GET_TICKET_WISE_CHECKIN_SUCCESS:
      return { ...state, isTicketWiseCheckersLoading: false, ticketWiseCheckersList: action.payload.data };
    case checkIn.GET_TICKET_WISE_CHECKIN_FAIL:
      return { ...state, isTicketWiseCheckersLoading: false, error: action.error };
    case checkIn.ADD_CHECKIN_REQUEST:
      return { ...state, isLoading: true };
    case checkIn.ADD_CHECKIN_SUCCESS:
      return { ...state, isLoading: false };
    case checkIn.ADD_CHECKIN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.UPDATE_CHECKIN_REQUEST:
      return { ...state, isLoading: true };
    case checkIn.UPDATE_CHECKIN_SUCCESS:
      return { ...state, isLoading: false };
    case checkIn.UPDATE_CHECKIN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.DELETE_CHECKIN_REQUEST:
      return { ...state, isLoading: true };
    case checkIn.DELETE_CHECKIN_SUCCESS:
      return { ...state, isLoading: false };
    case checkIn.DELETE_CHECKIN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export const reducerOne = (state = checkInInit, action) => {
  switch (action.type) {
    case checkIn.USER_CHECKIN_REQUEST:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.USER_CHECKIN_SUCCESS:
      return { ...state, isLoading: false, checkInUser: action.payload.data };
    case checkIn.USER_CHECKIN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.USER_CHECKOUT_REQUEST:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.USER_CHECKOUT_SUCCESS:
      return { ...state, isLoading: false, error: action.error };
    case checkIn.USER_CHECKOUT_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
