import { WindowUser } from "./type";

const initialAuthState = {
  WindowUserList: null,
  isLoading: true,
  error: null,
  ticketList: [],
  isTicketLoading: false,
  ticketItem: [],
  isTicketItemLoading: false,
  ticketBuyLoading: false,
  mobileLoading: false,
  dataFromNumber: null,
  windowReport: null,
  windowReportLoad: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case WindowUser.GET_WINDOW_USER_LIST_REQUEST:
      return { ...state, isLoading: true, WindowUserList: [] };
    case WindowUser.GET_WINDOW_USER_LIST_SUCCESS:
      return { ...state, isLoading: false, WindowUserList: action.payload.data };
    case WindowUser.GET_WINDOW_USER_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error, WindowUserList: [] };
    case WindowUser.ADD_WINDOW_USER_REQUEST:
      return { ...state, isLoading: true };
    case WindowUser.ADD_WINDOW_USER_SUCCESS:
      return { ...state, isLoading: false };
    case WindowUser.ADD_WINDOW_USER_FAIL:
      return { ...state, isLoading: false, error: action?.error };
    case WindowUser.GET_TICKET_LIST_REQUEST:
      return { ...state, isTicketLoading: true, ticketList: [] };
    case WindowUser.GET_TICKET_LIST_SUCCESS:
      return { ...state, isTicketLoading: false, ticketList: action.payload.data };
    case WindowUser.GET_TICKET_LIST_FAIL:
      return { ...state, isTicketLoading: false, error: action?.error, ticketList: [] };
    case WindowUser.GET_TICKET_ITEM_REQUEST:
      return { ...state, isTicketItemLoading: true, ticketItem: [] };
    case WindowUser.GET_TICKET_ITEM_SUCCESS:
      return { ...state, isTicketItemLoading: false, ticketItem: action.payload };
    case WindowUser.GET_TICKET_ITEM_FAIL:
      return { ...state, isTicketItemLoading: false, error: action?.error, ticketItem: [] };
    case WindowUser.UPDATE_WINDOW_USER_STATUS_REQUEST:
      return { ...state, isLoading: true };
    case WindowUser.UPDATE_WINDOW_USER_STATUS_SUCCESS:
      return { ...state, isLoading: false };
    case WindowUser.UPDATE_WINDOW_USER_STATUS_FAIL:
      return { ...state, isLoading: false, error: action?.error };
    case WindowUser.GET_USER_FROM_MOBILE_REQUEST:
      return { ...state, mobileLoading: true };
    case WindowUser.GET_USER_FROM_MOBILE_SUCCESS:
      return { ...state, mobileLoading: false, dataFromNumber: action?.payload?.data };
    case WindowUser.GET_USER_FROM_MOBILE_FAIL:
      return { ...state, mobileLoading: false, error: action?.error };
    case WindowUser.BUY_TICKET_REQUEST:
      return { ...state, ticketBuyLoading: true };
    case WindowUser.BUY_TICKET_SUCCESS:
      return { ...state, ticketBuyLoading: false };
    case WindowUser.BUY_TICKET_FAIL:
      return { ...state, ticketBuyLoading: false, error: action?.error };
    case WindowUser.GET_WINDOW_REPORT_REQUEST:
      return { ...state, windowReportLoad: true };
    case WindowUser.GET_WINDOW_REPORT_SUCCESS:
      return { ...state, windowReportLoad: false, windowReport: action?.payload?.data };
    case WindowUser.GET_WINDOW_REPORT_FAIL:
      return { ...state, windowReportLoad: false, error: action?.error };
    default:
      return state;
  }
};
