export const DiscountType = {

  // list discount
  GET_DISCOUNT_REQUEST: "GET_DISCOUNT_REQUEST",
  GET_DISCOUNT_SUCCESS: "GET_DISCOUNT_SUCCESS",
  GET_DISCOUNT_FAIL: "GET_DISCOUNT_FAIL",

  // add discount
  ADD_DISCOUNT_REQUEST: "ADD_DISCOUNT_REQUEST",
  ADD_DISCOUNT_SUCCESS: "ADD_DISCOUNT_SUCCESS",
  ADD_DISCOUNT_FAIL: "ADD_DISCOUNT_FAIL",

  // view discount
  GET_SINGLE_DISCOUNT_REQUEST: "GET_SINGLE_DISCOUNT_REQUEST",
  GET_SINGLE_DISCOUNT_SUCCESS: "GET_SINGLE_DISCOUNT_SUCCESS",
  GET_SINGLE_DISCOUNT_FAIL: "GET_SINGLE_DISCOUNT_FAIL",

  // update discount
  UPDATE_DISCOUNT_REQUEST: "UPDATE_DISCOUNT_REQUEST",
  UPDATE_DISCOUNT_SUCCESS: "UPDATE_DISCOUNT_SUCCESS",
  UPDATE_DISCOUNT_FAIL: "UPDATE_DISCOUNT_FAIL"
};
