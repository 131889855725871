import { transactionType } from "./type";

// Organizers intial state
const initialAuthState = {
  transaction: null,
  isTransactionLoading: true,
  error: null,
  eventDropdown: null,
  isLoading: false,
  transactionCard: null,
  transactionCardLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case transactionType.GET_REPORTS_TRANSACTION_REQUEST:
      return { ...state, isTransactionLoading: true };
    case transactionType.GET_REPORTS_TRANSACTION_SUCCESS:
      return { ...state, isTransactionLoading: false, transaction: action.payload.data };
    case transactionType.GET_REPORTS_TRANSACTION_FAIL:
      return { ...state, isTransactionLoading: false, error: action.error };
    case transactionType.GET_TRANSACTION_CARD_REQUEST:
      return { ...state, transactionCardLoading: true };
    case transactionType.GET_TRANSACTION_CARD_SUCCESS:
      return { ...state, transactionCardLoading: false, transactionCard: action.payload.data };
    case transactionType.GET_TRANSACTION_CARD_FAIL:
      return { ...state, transactionCardLoading: false };
    case transactionType.GET_EVENT_REQUEST:
      return { ...state, isLoading: true };
    case transactionType.GET_EVENT_SUCCESS:
      return { ...state, isLoading: false, eventDropdown: action.payload.data };
    case transactionType.GET_EVENT_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
