export const AuthType = {
  // login module types
  LOG_OUT: "LOG_OUT",
  USER_SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_OTP_SUCCESS: "USER_OTP_SUCCESS",
  USER_SIGNIN_FAIL: "USER_SIGNIN_FAIL",
  AUTH_CHEK_REQUEST: "AUTH_CHEK_REQUEST",
  AUTH_CHEK_SUCCESS: "AUTH_CHEK_SUCCESS",
  AUTH_CHEK_FAIL: "AUTH_CHEK_FAIL",
  REFRESH_CAPTCHA_REQUEST: "REFRESH_CAPTCHA_REQUEST",
  REFRESH_CAPTCHA_SUCCESS: "REFRESH_CAPTCHA_SUCCESS",
  REFRESH_CAPTCHA_FAIL: "REFRESH_CAPTCHA_FAIL",
  CHEK_CAPTCHA_REQUEST: "CHEK_CAPTCHA_REQUEST",
  CHEK_CAPTCHA_SUCCESS: "CHEK_CAPTCHA_SUCCESS",
  CHEK_CAPTCHA_FAIL: "CHEK_CAPTCHA_FAIL",
  PASSWORD_CHANGE_REQUEST: "PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAIL: "PASSWORD_CHANGE_FAIL",

  // register module
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",

  //Profile Module
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAIL: "GET_ADDRESS_FAIL",
  RESEND_VERIFY_EMAIL_REQUEST: "RESEND_VERIFY_EMAIL_REQUEST",
  RESEND_VERIFY_EMAIL_SUCCESS: "RESEND_VERIFY_EMAIL_SUCCESS",
  RESEND_VERIFY_EMAIL_FAIL: "RESEND_VERIFY_EMAIL_FAIL",
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAIL: "VERIFY_EMAIL_FAIL",

  // register module
  AUTO_LOGIN_REQUEST: "AUTO_LOGIN_REQUEST",
  AUTO_LOGIN_SUCCESS: "AUTO_LOGIN_SUCCESS",
  AUTO_LOGIN_FAIL: "AUTO_LOGIN_FAIL",

  // add payment
  SAVE_PAYMENT_REQUEST: "SAVE_PAYMENT_REQUEST",
  SAVE_PAYMENT_SUCCESS: "SAVE_PAYMENT_SUCCESS",
  SAVE_PAYMENT_FAIL: "SAVE_PAYMENT_FAIL",

  // get payment
  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAIL: "GET_PAYMENT_FAIL",

  // update payment
  UPDATE_PAYMENT_REQUEST: "UPDATE_PAYMENT_REQUEST",
  UPDATE_PAYMENT_SUCCESS: "UPDATE_PAYMENT_SUCCESS",
  UPDATE_PAYMENT_FAIL: "UPDATE_PAYMENT_FAIL",

  // verify payment
  VERIFY_PAYMENT_REQUEST: "VERIFY_PAYMENT_REQUEST",
  VERIFY_PAYMENT_SUCCESS: "VERIFY_PAYMENT_SUCCESS",
  VERIFY_PAYMENT_FAIL: "VERIFY_PAYMENT_FAIL",

  // Notification modal list
  NOTIFICATION_REQUEST: "NOTIFICATION_REQUEST",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAIL: "NOTIFICATION_FAIL",

  // Notification read
  NOTIFICATION_READ_REQUEST: "NOTIFICATION_READ_REQUEST",
  NOTIFICATION_READ_SUCCESS: "NOTIFICATION_READ_SUCCESS",
  NOTIFICATION_READ_FAIL: "NOTIFICATION_READ_FAIL",
  
  // get contrey
  GET_COUNTRY: "GET_COUNTRY"
};
