import { ArtistType } from "./type";

// Artists intial state
const initialAuthState = {
  artistList: null,
  isLoading: true,
  error: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case ArtistType.GET_ARTISTS_LIST_REQUEST:
      return { ...state, isLoading: true };
    case ArtistType.GET_ARTISTS_LIST_SUCCESS:
      return { ...state, isLoading: false, artistList: action.payload.data };
    case ArtistType.GET_ARTISTS_LIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case ArtistType.ADD_ARTIST_REQUEST:
      return { ...state, isLoading: true };
    case ArtistType.ADD_ARTIST_SUCCESS:
      return { ...state, isLoading: false };
    case ArtistType.ADD_ARTIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case ArtistType.UPDATE_ARTIST_REQUEST:
      return { ...state, isLoading: true };
    case ArtistType.UPDATE_ARTIST_SUCCESS:
      return { ...state, isLoading: false };
    case ArtistType.UPDATE_ARTIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case ArtistType.DELETE_ARTIST_REQUEST:
      return { ...state, isLoading: true };
    case ArtistType.DELETE_ARTIST_SUCCESS:
      return { ...state, isLoading: false };
    case ArtistType.DELETE_ARTIST_FAIL:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
